import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState, Fragment } from "react";

import {
  Box,
  Button,
  Typography,
  Skeleton,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
} from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import currency from "currency.js";
import dayjs from "dayjs";
import { times } from "lodash";
import Slider from "react-slick";

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import CatalogSectionAd from "../../components/Catalog/CatalogSectionAd";
import CatalogSectionContainer from "../../components/Catalog/CatalogSectionContainer";
import clockArrow from '../../components/Catalog/images/clock-arrow.svg';
import deliveryCar from '../../components/Catalog/images/delivery-car.svg';
import pinMap from '../../components/Catalog/images/pin-map-label.svg';
import no_img from '../../components/images/no-image.png';
import ModalBuyProduct from "../../components/ModalBuyProduct/ModalBuyProduct";
import PageBox from "../../components/PageBox";
import SEO from "../../components/SEO";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

PageProduct.propTypes = {
  id: PropTypes.string,
};

PageProduct.defaultProps = {
  id: '',
}

export default function PageProduct({ id }) {
  let tabs = [
    {
      name: 'Описание',
      key: 'description',
    }
  ]

  const style = {
    wrapperPage: {
      display: 'flex',
      flexWrap: {
        xs: 'wrap',
        sm: 'wrap',
        lg: 'nowrap'
      },
      paddingTop: {
        xs: '16px',
        sm: '36px',
        lg: '36px',
      },
    },
    wrapperImg: {
      width: {
        xs: '100%',
        sm: '100%',
        lg: '470px'
      },
      marginRight: {
        xs: 0,
        md: 0,
        lg: '40px'
      },
      position: 'relative',

      '& .slick-arrow': {
        display: 'none !important',
      },
      '& .slick-track img': {
        borderRadius: '4px',
      },
      '& .slick-dots': {
        display: 'flex !important',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        position: 'relative',
      },
      '& .slick-dots li': {
        height: 'auto',
        width: 'auto',
        border: '1px solid transparent',
        borderRadius: '4px',
        overflow: 'hidden',

        '&:hover': {
          border: '1px solid #E7E8EA',
        },
      },
      '& .slick-dots li.slick-active': {
        border: '1px solid #FF780F',
      },
      '& .slick-slide': {
        height: '470px !important',
        minHeight: '100% !important',

        '& > div': {
          height: 'calc(100% - 30px)',
          minHeight: 'calc(100% - 30px)',
          width: '100%',
          minWidth: '100%',

          '& > div': {
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            display: 'flex !important',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '4px',

            '& img': {
              height: '100% !important',
              width: 'auto !important',
              maxWidth: 'unset !important',
            }
          }
        }
      }
    },
    imgPlacenholder: {
      background: '#E7E8EA',
      borderRadius: '4px',
      fontSize: '14px',
      fontWeight: 500,
      padding: '2px 8px',
    },
    removeBack: {
      display: {
        xs: 'flex',
        sm: 'flex',
        lg: 'none',
      },
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid #E7E8EA',
      borderRadius: '30px',
      position: 'absolute',
      background: '#FFFFFF',
      left: 0,
      top: 0,
      zIndex: '3',
      height: '36px',
      width: '36px',
      boxShadow: {
        xs: '0px 2px 8px rgba(47, 45, 40, 0.1)',
        sm: 'none',
      }
    },
    wrapperContent: {
      width: {
        xs: '100%',
        sm: '100%',
        lg: 'calc(100% - 470px)'
      },
      marginTop: {
        xs: '60px',
        sm: '60px',
        lg: '0px',
      },
    },
    wrapperPrice: {
      background: '#ffffff',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: '8px',
      padding: '16px',
      boxShadow: '0px 6px 16px rgba(47, 45, 40, 0.1)',
      flexWrap: {
        xs: 'wrap',
        sm: 'wrap',
        lg: 'nowrap',
      },
      // marginBottom: '24px',
    },
    reserved: {
      color: '#f80024',
      fontWeight: 700,
      fontSize: '16px',
      marginBottom: '16px',
    },
    salePrice: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',

      '& .sale': {
        background: '#67BF3D',
        borderRadius: '4px',
        color: '#ffffff',
        fontSize: '16px !important',
        fontWeight: '700 !important',
        height: '19px',
        lineHeight: '19px',
        padding: '0 4px',
      },
      '& .total': {
        fontSize: '16px !important',
        color: '#6E7884',
        fontWeight: '500 !important',
        position: 'relative',

        '&:before': {
          content: "''",
          display: 'inline-block',
          width: 'calc(100% + 10px)',
          height: '3px',
          background: '#FF780F',
          position: 'absolute',
          left: '-6px',
          bottom: '11px',
          opacity: 0.6,
          borderRadius: '8px',
          transform: 'rotate(350deg)',
        }
      },
    },
    twoPrice: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: {
        xs: 'wrap',
        sm: 'nowrap',
        lg: 'nowrap',
      },

      '& .price': {
        fontSize: '32px !important',
        color: '#FF780F',
        fontWeight: '700 !important',
        marginRight: '8px',
        width: {
          xs: '100%',
          sm: 'auto',
          lg: 'auto',
        },
      },
      '& .vat': {
        color: '#868E98',
        fontSize: '16px !important',
        fontWeight: '400 !important',
        marginTop: {
          xs: '0',
          sm: '10px',
          lg: '10px',
        },
        marginBottom: {
          xs: '16px',
          sm: '0',
          lg: '0',
        },
      }
    },
    buttonBay: {
      background: '#FF780F',
      color: '#ffffff',
      fontSize: '18px',
      fontWeight: '500',
      display: 'flex',
      alignItems: 'center',
      padding: '24px',
      borderRadius: '8px',
      height: '56px',
      boxShadow: 'none',
      cursor: 'pointer',
      textTransform: 'none',
      width: {
        xs: '100%',
        sm: 'auto',
        lg: 'auto',
      },

      '&:hover': {
        background: '#FF9C14',
        boxShadow: "0px 10px 24px rgba(255, 120, 15, 0.3)",
      },
      '&:active': {
        background: '#CF660F',
        boxShadow: 'none',
      },
      '&:disabled': {
        background: "#CFD2D6 !important",
        boxShadow: 'none !important',
        color: "#ffffff",
      }
    },
    title: {
      fontSize: {
        xs: '18px',
        sm: '24px',
        lg: '40px',
      },
      fontWeight: 700,
      marginBottom: '16px',
      lineHeight: {
        xs: 'normal',
        sm: 'normal',
        lg: '48px',
      },
    },
    minInf: {
      fontSize: {
        xs: '14px',
        sm: '16px',
        lg: '16px',
      },
      marginBottom: '8px',
      display: 'flex',
      alignItems: 'center',

      '& span': {
        fontWeight: 500,
        marginLeft: '4px',
      },
      '&:last-child': {
        marginBottom: '24px',
      }
    },
    wrapperCondition: {
      marginBottom: {
        xs: '12px',
        sm: '12px',
        lg: '4px'
      },
      display: 'flex',
    },
    condition: {
      fontSize: {
        xs: '16px',
        sm: '16px',
        lg: '16px',
      },
      marginTop: 0,
      marginRight: '6px',
    },
    countsQuality: {
      display: {
        xs: 'none',
        sm: 'flex',
        lg: 'flex',
      },
      gap: '5px',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    countQuality: {
      width: '8px',
      height: '8px',
      borderRadius: '2px',
      background: '#CFD2D6',
    },
    kit: {
      background: '#21A2FF',
      color: '#FFFFFF',
      borderRadius: '2px',
      fontWeight: 500,
      position: 'absolute',
      right: '18px',
      top: '18px',
      zIndex: 2,
      fontSize: '14px',
      padding: '0 7px',
    },
    wrapperTabs: {
      overflow: 'hidden',
      borderRadius: '16px',
      marginBottom: '20px',
      marginTop: '26px',
      width: '100%',
    },
    tabs: {
      display: 'flex',
      flexWrap: {
        xs: 'wrap',
        sm: 'nowrap',
      }
    },
    tab: {
      fontSize: {
        xs: '16px',
        sm: '22px',
        lg: '22px'
      },
      padding: '16px 18px',
      width: 'auto',
      textAlign: 'center',
      cursor: 'pointer',
      fontWeight: '500',
      color: '#6E7884',

      '&.active': {
        cursor: 'default',
        borderBottom: '4px solid #FF780F',
        color: '#0D1D32'
      }
    },
    tabContent: {
      padding: {
        xs: '18px 0',
        sm: '42px 0',
      },
      fontSize: {
        xs: '12px',
        sm: '18px',
        lg: '18px',
      },
      overflow: {
        xs: 'scroll',
        sm: 'auto',
        lg: 'initial',
      }
    },
    scrollTable: {

    },
    recomendation: {
    },
    recomendationTitle: {
      fontSize: {
        xs: '20px !important',
        sm: '32px !important',
        lg: '32px !important',
      },
      fontWeight: '500',
      marginBottom: {
        xs: '25px',
        sm: '73px',
        lg: '46px',
      },
    },
    sliderRecomendation: {
      width: '99%',
      overflow: 'hidden',

      '& .slick-arrow': {
        display: 'none !important',
      },
      '& .arrow-custom': {
        display: 'none !important',
      },
      '& .slick-center .arrow-custom': {
        display: 'flex !important',
      },
    },
    sliderRecomendationSlide: {
      background: '#FFFFFF',
      borderRadius: '12px',
      padding: {
        xs: '10px',
        sm: '20px',
        lg: '20px',
      },
      margin: {
        xs: '10px 7px 25px !important',
        sm: '10px 7px 84px !important',
        lg: '10px 7px 84px !important',
      },
      border: '1px solid #E7E8EA',
      position: 'relative',
      transition: 'box-shadow 0.20s',

      '&:hover': {
        boxShadow: '0px 2px 8px rgba(47, 45, 40, 0.12)',
        transition: 'box-shadow 0.20s',
        border: '1px solid transparent',

        '& .slick-dots li': {
          overflow: 'visible',
          opacity: 1,
        }
      }
    },
    sliderRecomendationImg: {
      '& .slick-track img': {
        borderRadius: '4px',
      },
      '& .slick-dots': {
        display: 'flex !important',
        position: 'relative',
        bottom: '16px',
        justifyContent: 'space-between',
      },
      '& .slick-dots li': {
        width: '100%',
        background: '#BEBEBE',
        height: '5px',
        borderRadius: '10px',
        overflow: 'hidden',
        opacity: 0,
        transition: 'opacity 0.2s',

        '&.slick-active': {
          background: '#FF780F',
        },
        '& > div': {
          height: '100%',
        }
      },
    },
    sliderRecomendationTitle: {
      fontSize: {
        xs: '12px',
        sm: '18px',
        lg: '18px',
      },
      fontWeight: '400',
    },
    sliderRecomendationPrice: {
      color: '#FF780F',
      fontWeight: 600,
      width: {
        xs: '100%',
        sm: 'auto',
        lg: 'auto',
      },
      display: 'inline-block',
      fontSize: {
        xs: '18px',
        sm: '24px',
        lg: '24px',
      },
      marginTop: {
        xs: '6px',
        sm: '9px',
        lg: '9px',
      },
    },
    sliderRecomendationPriceOld: {
      fontSize: '16px !important',
      color: '#6E7884',
      fontWeight: '500 !important',
      position: 'relative',
      width: 'auto',
      display: 'inline-block',
      marginLeft: '6px',

      '&:before': {
        content: "''",
        display: 'inline-block',
        width: '120%  ',
        height: '3px',
        background: '#FF780F',
        position: 'absolute',
        left: '-6px',
        bottom: '11px',
        opacity: 0.6,
        borderRadius: '8px',
        transform: 'rotate(350deg)',
      }
    },
    sliderRecomendationVat: {
      color: '#6E7884',
      fontSize: '12px',
    },
    sliderRecomendationSale: {
      background: '#67BF3D',
      borderRadius: '4px',
      color: '#ffffff',
      fontSize: '16px !important',
      fontWeight: '700 !important',
      height: '19px',
      lineHeight: '19px',
      padding: '0 4px',
      width: 'auto',
      position: 'absolute',
      left: 20,
      top: 20,
    },
    sliderRecomendationKit: {
      background: '#21A2FF',
      color: '#FFFFFF',
      borderRadius: '2px',
      fontWeight: 500,
      position: 'absolute',
      right: '18px',
      top: '18px',
      zIndex: 2,
      fontSize: '14px',
      padding: '0 7px',
    },
    modal: {
      '& .MuiDialog-paper': {
        maxWidth: '596px',
        position: 'relative',
        padding: '0',
        boxShadow: '0px 20px 64px rgba(47, 45, 40, 0.12)',
        borderRadius: '16px',
      },

      '& .MuiDialogTitle-root': {
        padding: '72px 86px 37px',

        '& .MuiIconButton-root': {
          position: 'absolute',
          top: '24px',
          right: '24px',
        }
      },
      '& .MuiDialogContent-root': {
        padding: '0 86px',

        '& .MuiFormControl-root': {
          marginTop: 0,
        },
        '& .cheked-modal': {
          display: 'flex',
          alignItems: 'center',
          fontSize: '16px',
          fontWeight: 500,

          '& .MuiCheckbox-root': {
            paddingLeft: 0,
          },
          '& a': {
            color: '#0D1D32 !important',
          },
        },
        '& .button-modal': {
          marginBottom: '57px',
          display: 'flex',
          justifyContent: 'center',

          '& button': {
            textTransform: 'none',
            fontSize: '18px',
            fontWeight: 500,
            width: 'auto',
            background: '#FF780F',
            borderRadius: '30px',
            boxShadow: 'none',
            height: '56px',

            '& .MuiTouchRipple-root': {
              display: 'none'
            },
            '&:hover': {
              background: '#FF9C14',
              boxShadow: '0px 10px 24px rgba(255, 120, 15, 0.3)',
            },
            '&:active': {
              background: '#CF660F',
              boxShadow: 'none',
            }
          }
        }
      }
    },
    modalImg: {
      '& .MuiDialog-paper': {
        maxWidth: '800px',
        width: '100%',
        position: 'relative',
        padding: '0',
        boxShadow: '0px 20px 64px rgba(47, 45, 40, 0.12)',
        borderRadius: '16px',
      },

      '& .MuiDialogTitle-root': {
        padding: '59px 64px 35px',

        '& .MuiIconButton-root': {
          position: 'absolute',
          top: '24px',
          right: '24px',
        }
      },
      '& .MuiDialogContent-root': {
        padding: '0 64px 64px',
      }
    }
  }

  const query = `
   query {
      product (
        productId: ${id}
      ) {
        id
        externalId
        images {
          url
          filename
        }
        categories {
          id
          productsCount
          title
        }
        title
        description
        price
        discount
        reserved
        vatRate
        address
        pricesForEachUnitEnabled
        quality
        operatedSince
        shippingStatus
        items {
          title
          quantity
          unit
          price
          vatRate
          images {
            filename
            url
          }
        }
      }
    }
  `;

  const [showModal, setShowModal] = useState(false);

  const toggleModal = (_, isOpened) => {
    setShowModal(isOpened)
  }

  const [showModalFoto, setShowModalFoto] = useState(false);
  const [indexFoto, setIndexFoto] = useState(null);

  const toggleModalFoto = (index) => {
    setShowModalFoto(!showModalFoto);

    if (!showModalFoto) {
      setIndexFoto(index);
    } else {
      setIndexFoto(null)
    }
  }

  const [product, setProduct] = useState({})

  const [allImg, setAllImg] = useState([])

  useEffect(() => {
    fetch(`${process.env.GATSBY_API_URL_SHOP}/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authentication": `${process.env.GATSBY_TOKEN_SHOP}`,
      }, body: JSON.stringify({
        query
      })
    }).then(response => {
      return response.json();
    }).then(data => {
      setProduct(data?.data?.product)
      setLoader(false);

      let images = [];
      if (data?.data?.product?.images && data?.data?.product?.images.length > 0) {
        images = images.concat(data.data.product.images)
      }

      if (data?.data?.product?.items && data?.data?.product?.items.length > 0) {
        data?.data?.product?.items.forEach((item, index) => {
          item.images.forEach(img => {
            img.name = `Товар №${index + 1}`;
          })

          images = images.concat([...item.images]);
        })
      }

      setAllImg(images);
    })
  }, [query])

  const [loader, setLoader] = useState(true);

  const SampleNextArrowS = props => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;

    return (
      <div
        onClick={onClick}
        style={{
          fontSize: 0,
          lineHeight: 0,
          cursor: "pointer",
          position: 'absolute',
          top: '223px',
          marginTop: '-12px',
          right: '32px',
          display: "block",
          zIndex: 1,
          background: '#fff',
          borderRadius: '25px',
          border: '1px solid #babbbb',
          opacity: '0.6'
        }}
      >
        <KeyboardArrowRightIcon sx={{ color: '#B6BBC2' }} />
      </div>
    );
  }

  const SamplePrevArrowS = props => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        onClick={onClick}
        style={{
          fontSize: 0,
          lineHeight: 0,
          cursor: "pointer",
          position: 'absolute',
          top: '223px',
          marginTop: '-12px',
          left: '32px',
          display: "block",
          zIndex: 1,
          background: '#fff',
          border: '1px solid #babbbb',
          borderRadius: '25px',
          opacity: '0.6'
        }}
      >
        <KeyboardArrowLeftIcon sx={{ color: '#B6BBC2' }} />
      </div>
    );
  }

  const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrowS />,
    prevArrow: <SamplePrevArrowS />,
    // eslint-disable-next-line react/display-name
    customPaging: (i) => {
      return (
        <Box sx={{ overflow: "hidden", maxWidth: "84px", width: "100%", height: "84px", display: "flex !important", justifyContent: "center", "& > img": { maxWidth: "unset !important" } }}>
          <img alt={i} src={allImg[i].url} style={{ height: "100%", }} />
        </Box>
      );
    },
  };

  const settingsRecomendation = {
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          className: "center",
          centerMode: true,
          infinite: true,
          centerPadding: "60px",
          slidesToShow: 1,
          speed: 500
        }
      },
    ]
  };

  const SampleNextArrow = props => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        className={'arrow-custom'}
        onClick={onClick}
        style={{
          position: 'absolute',
          top: '50%',
          transform: 'translate(0px, -50%)',
          height: '100%',
          right: 0,
          display: 'flex',
          alignItems: 'center',
          width: '30px',
          zIndex: 3,
        }}
      >
        <KeyboardArrowRightIcon sx={{ color: '#CFD2D6' }} />
      </div>
    );
  }

  const SamplePrevArrow = props => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        className={'arrow-custom'}
        onClick={onClick}
        style={{
          position: 'absolute',
          top: '50%',
          transform: 'translate(0px, -50%)',
          height: '100%',
          left: 0,
          display: 'flex',
          alignItems: 'center',
          width: '30px',
          zIndex: 3,
        }}
      >
        <KeyboardArrowLeftIcon sx={{ color: '#CFD2D6' }} />
      </div>
    );
  }

  const settingsRecomendationImg = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // eslint-disable-next-line react/display-name
    customPaging: (i) => {
      return (
        <Box></Box>
      );
    },
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          dots: false,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
        }
      },
    ]
  }

  const qualityCalculation = (type) => {
    let text = '',
      color = '',
      count = 0;

    switch (type) {
      case 1:
      case 2:
        text = 'Плохое';
        color = '#FF5550';
        count = 2;

        break;

      case 'satisfactory':
        text = 'Удовлетворительное!';
        color = '#D4B200';
        count = 3;

        break;

      case 'good':
        text = 'Хорошее!';
        color = '#35CFD9';
        count = 4;

        break;

      case 'perfect':
        text = 'Отличное!';
        color = '#67BF3D';
        count = 5;

        break;

      case 'newest':
        text = 'Новое 🔥';
        color = '#67BF3D';
        count = 'none';

        break;

      default:
        text = 'Не указано';
        color = '#2F2F2F';
        count = 0;
    }

    return (
      <Box sx={style.wrapperCondition}>
        <Typography sx={style.condition}>Состояние <span style={{ color: color, fontWeight: 'bold' }}>{text}</span></Typography>
        {count !== 'none' && (
          <Box sx={style.countsQuality}>
            {times(count, _ => {
              return (
                <Box sx={{ ...style.countQuality, background: color }}></Box>
              )
            })}

            {times(5 - count, _ => (<Box sx={{ ...style.countQuality }}></Box>))}
          </Box>
        )}
      </Box>
    )
  }

  const [activeTab, setActiveTab] = useState('description');
  const onToggleTabs = tab => {
    setActiveTab(tab)
  };

  const formatMoney = useCallback((value) => {
    return currency(value, { precision: 0 }).format({
      separator: ' ',
      symbol: '¤',
      pattern: '# !',
    });
  }, []);

  const shippingStatus = type => {
    let text;

    switch (type) {
      case "READY":
        text = 'Готово';
        break;

      case 'APPROVAL':
        text = 'По согласованию';
        break;

      default:
        text = '';
    }

    return text;
  }

  return (
    loader === true ? (
      <noindex>
        <Box sx={{ minHeight: '600px', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', flexDirection: 'column' }}>
          <Skeleton animation="wave" width={'160px'} />

          <Typography sx={{ fontSize: '20px', marginTop: '30px', }}>Загрузка</Typography>
        </Box>
      </noindex>
    ) : (
      <>
        <PageBox>
          <SEO pageData={{ title: `Arenza.renew 🔥 | ${product.title}` }} />

          <CatalogSectionContainer sx={{ marginTop: 0, marginBottom: 0, }}>
            <Breadcrumbs category={product.categories} />
          </CatalogSectionContainer>

          <CatalogSectionContainer sx={style.wrapperPage}>
            <Box sx={style.wrapperImg}>
              <a href={'/'}>
                <Box sx={style.removeBack}>
                  <ArrowBackIosIcon sx={{ color: '#CFD2D6', fontSize: '12px', marginRight: '-3px' }} />
                </Box>
              </a>

              {allImg && allImg.length > 0 ? (
                <Slider {...settings}>
                  {allImg.map((element, index) => (
                    <Fragment key={element.filename + index}>
                      <Box>
                        <Box alt={element.filename} component="img" src={element.url} />
                      </Box>

                      {element.name && (
                        <Box component="p" sx={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
                          <Typography component="span" sx={style.imgPlacenholder}>{element.name}</Typography>
                        </Box>
                      )}
                    </Fragment>
                  ))}
                </Slider>
              ) : (
                <img alt={''} src={no_img} />
              )}
            </Box>

            <Box sx={style.wrapperContent}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: { xs: 'wrap', sm: 'wrap', lg: 'wrap' } }}>
                <Box>
                  {qualityCalculation(product.quality)}

                  <Typography component="h1" sx={style.title}>{product.title}</Typography>

                  <Box sx={{ display: 'flex', marginBottom: '24px' }}>
                    <Typography sx={{ color: '#6E7884', fontSize: '16px', fontWeight: 500, marginRight: '6px' }}>
                      ID Товара:
                    </Typography>
                    {" "}
                    <Typography sx={{ color: '#0D1D32', fontSize: '16px', fontWeight: 500 }}>
                      {product.externalId}
                    </Typography>
                  </Box>

                  <Box>
                    {product?.reserved && (
                      <Typography sx={style.reserved}>
                        Зарезервирован
                      </Typography>
                    )}

                    <Typography sx={style.minInf}>
                      <Box
                        alt="Начало эксплуатации"
                        component="img"
                        src={clockArrow}
                        sx={{ display: 'inline-block', marginRight: '14px' }}
                        width={16}
                      />

                      Начало эксплуатации
                      {product.operatedSince ? (
                        <>
                          <span>{dayjs(product.operatedSince).format("DD.MM.YYYY")}</span>
                          <span>({dayjs().diff(dayjs(product.operatedSince), 'month')} {" "} мес.)</span>
                        </>
                      ) : (
                        <span>---</span>
                      )}
                    </Typography>
                    <Typography sx={style.minInf}>
                      <Box
                        alt="Местонахождение"
                        component="img"
                        src={pinMap}
                        sx={{ display: 'inline-block', marginRight: '14px' }}
                        width={16}
                      />
                      Местонахождение <span>{product?.address || '---'}</span>
                    </Typography>
                    <Typography sx={style.minInf}>
                      <Box
                        alt="Статус отгрузки"
                        component="img"
                        src={deliveryCar}
                        sx={{ display: 'inline-block', marginRight: '14px' }}
                        width={20}
                      />
                      К отгрузке <span style={{ color: '#67BF3D' }}>{shippingStatus(product.shippingStatus)}</span>
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ background: '#F3F4F5', padding: '24px', borderRadius: '8px', marginBottom: '24px', width: '100%' }}>
                  <Box sx={style.wrapperPrice}>
                    <Box>
                      <Box sx={style.salePrice}>
                        {product.discount > 0 && (
                          <Typography className={'sale'}>-{product.discount}%</Typography>
                        )}
                        <Typography className={'total'}>
                          {product.discount > 0 && formatMoney(product.price)}
                        </Typography>
                      </Box>
                      <Box sx={style.twoPrice}>
                        <Typography className={'price'}>
                          {product.discount > 0 ? (
                            formatMoney(product.price - (product.price / 100 * product.discount))
                          ) : (
                            formatMoney(product.price)
                          )}
                        </Typography>

                        <Typography className={'vat'}>
                          {product.vatRate > 0 ? (
                            product.discount > 0 ? (
                              <>
                                НДС {" "} {formatMoney((product.price - (product.price / 100 * product.discount)) - (product.price - (product.price / 100 * product.discount)) / (1 + product.vatRate))}
                              </>
                            ) : (
                              <>
                                НДС {" "} {formatMoney(product.price - product.price / (1 + product.vatRate))}
                              </>
                            )
                          ) : 'Без НДС'}
                        </Typography>
                      </Box>
                    </Box>

                    <Button onClick={toggleModal.bind("", true)} sx={style.buttonBay}>
                      Купить
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </CatalogSectionContainer>

          <CatalogSectionContainer>
            <Box>
              <Box sx={style.wrapperTabs}>
                <Box sx={style.tabs}>
                  {tabs.map(elem => (
                    <Typography
                      className={activeTab === elem.key ? 'active' : null}
                      key={elem.key}
                      onClick={onToggleTabs.bind(this, elem.key)}
                      sx={style.tab}>
                      {elem.name}
                    </Typography>
                  ))}
                </Box>

                <Box sx={style.tabContent}>
                  {activeTab === 'description' && (
                    product.description
                  )}
                </Box>
              </Box>
            </Box>
          </CatalogSectionContainer>


          <CatalogSectionContainer sx={{ paddingTop: '36px' }}>
            {false && (
              <Box sx={style.recomendation}>
                <Typography sx={style.recomendationTitle}>Вас могут заинтересовать</Typography>
                <Box sx={style.sliderRecomendation}>
                  <Slider {...settingsRecomendation}>
                    {product.recomendation.map((element, index) => {
                      return (
                        <Box key={`sliderRecomendation${index}`} >
                          <Box sx={style.sliderRecomendationSlide}>
                            <Box sx={style.sliderRecomendationImg}>
                              <Slider {...settingsRecomendationImg}>
                                {element.img.map((img, indexImg) => {
                                  return <Box component="img" key={`sliderRecomendationItem${indexImg}`} src={img} />
                                })}
                              </Slider>
                            </Box>

                            <Typography sx={style.sliderRecomendationTitle}>{element.title}</Typography>

                            <Typography sx={style.sliderRecomendationPrice}>{element.price} ₽</Typography>

                            {element.oldPrice && (
                              <Typography sx={style.sliderRecomendationPriceOld}>{element.oldPrice} ₽</Typography>
                            )}

                            <Typography sx={style.sliderRecomendationVat}>В т.ч. НДС {element.vat}%</Typography>

                            <Typography sx={style.sliderRecomendationSale}>-30%</Typography>
                          </Box>
                        </Box>
                      )
                    })}
                  </Slider>
                </Box>
              </Box>
            )}

            <CatalogSectionAd type={'horizon'} />
          </CatalogSectionContainer>
        </PageBox>

        {showModal && <ModalBuyProduct funcHideModal={toggleModal} id={product.id} />}
        {showModalFoto && (
          <Dialog onClose={toggleModalFoto} open={showModalFoto} sx={style.modalImg}>
            <DialogTitle
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography sx={{ fontSize: { xs: '22px', sm: '26px', lg: '26px', }, fontWeight: 700 }}>Фото SKU</Typography>

              <IconButton aria-label="close" onClick={toggleModalFoto}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: '14px' }}>
                {product.items[indexFoto].images.map(image => (
                  <Box key={image.filename} sx={{ width: "157px", height: "157px", border: '1px solid #E7E8EA', borderRadius: "8px", overflow: 'hidden', }}>
                    <Box sx={{ overflow: 'hidden', width: '100%', height: '100%', display: 'flex !important', alignItems: 'center', justifyContent: 'center', borderRadius: '4px', }}>
                      <Box alt={image.filename} component="img" src={image.url} sx={{ height: '100% !important', width: 'auto !important', maxWidth: 'unset !important', }} />
                    </Box>
                  </Box>
                ))}
              </Box>
            </DialogContent>
          </Dialog>
        )}
      </>
    )
  );
}